import Dexie from 'dexie'

const db = new Dexie('pheno-sight')

db.version(1).stores({
  experiment: 'code, name, created, changed',
  fly: 'code, experiment, name, date, created, changed, active'
})

export default db
